import type { GenerateBranchClustersPreview } from '~/composables/types/api/searchDiscover/generateTransfer';
import type { ApiResponse } from '~~/composables/types/api/apiResponse';
import handleError from '~/composables/api/handleResponse';
import { fetchOrderDetermination } from '~/composables/api/apiFactories/fetchOrderDetermination';

export default async function getGenerateBranchClustersPreview(
    options?: { signal: AbortSignal }
): Promise<GenerateBranchClustersPreview | ApiResponse> {
    const url = `/branch-clusters-generate-preview`;
    const { fetchSdApi } = fetchOrderDetermination();

    try {
        const response = await fetchSdApi(url, options) as any;

        if (response.error) {
            return handleError(response);
        }

        return response.data[0].attributes;
    } catch (error) {
        return handleError(error);
    }
}
